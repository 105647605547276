import React, { FC, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { throttle } from 'lodash';
import { Link } from 'gatsby';

import useStickyObserver from 'hooks/useStikyObserver';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { ISubNavigationALPMobileProps } from './models';
import './SubNavigationALPMobile.scss';
import './SubNavigationALPMobileOverride.scss';

const SubNavigationALPMobile: FC<ISubNavigationALPMobileProps> = ({
  submenuTitle,
  links,
  settings: { title },
}) => {
  const submenu = useRef<HTMLDivElement>(null);
  const [isSticky] = useStickyObserver(submenu);
  const [isOpen, setIsOpen] = useState(false);
  const cx = classnames({
    'sub-navigation-alp-mobile': true,
    'sub-navigation-alp-mobile--is-sticky': isSticky,
    'sub-navigation-alp-mobile--is-open': isOpen,
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const hideMenu = () => {
      setIsOpen(false);
    };
    const trottledFunction = throttle(hideMenu, 500);

    if (!isOpen) {
      window.removeEventListener('scroll', trottledFunction);
    } else {
      window.addEventListener('scroll', trottledFunction);
    }

    return () => window.removeEventListener('scroll', trottledFunction);
  }, [isOpen]);

  return links?.length ? (
    <nav ref={submenu} className={cx} aria-label={title} data-testid="sub-navigation-alp-mobile">
      {submenuTitle ? (
        <span className="sub-navigation-alp-mobile__title">{submenuTitle}</span>
      ) : null}
      <div className="sub-navigation-alp-mobile__bg">
        <Button classes="sub-navigation-alp-mobile__button" onClick={handleClick} variant="link">
          {title}
          <IconCustom icon="chevron-down" />
        </Button>
        <ul className="sub-navigation-alp-mobile__list">
          {links.map((item) => (
            <li key={item.name}>
              <Link to={item.link[0].url} data-title={item.name}>
                <DangerouslySetInnerHtml element="span" html={item.name} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  ) : null;
};

export default SubNavigationALPMobile;
