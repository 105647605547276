import React, { FC, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import { useLocation } from '@reach/router';
import classnames from 'classnames';

import TopBanner from 'components/TopBanner';
import Layout from 'components/Layout';
import SubNavigationALP from 'components/SubNavigationALP';
import ArticleCardsList from 'components/ArticleCardsList';
import FeaturedArticle from 'components/FeaturedArticle';
import SubNavigationALPMobile from 'components/SubNavigationALPMobile';
import HomeBanner from 'components/HomeBanner';
import BreadCrumbs from 'components/common/BreadCrumbs';

import useScreenRecognition from 'hooks/useScreenRecognition';
import { getSettingsPropsByStructure } from 'utils/parseHelpers';

import { IPropsArticleListingPage } from './model';
import './ArticleListingPage.scss';

const ArticleListingPage: FC<IPropsArticleListingPage> = (props) => {
  const {
    path,
    data: {
      articles: intialArticles,
      allArticles,
      page: {
        nodes: [
          {
            seoMetaTitle,
            seoMetaDescription,
            seoMetaKeywords,
            seoExternalHreflangs,
            openGraphImageUrl,
            topBanner,
            submenu,
            useDropdownNavOnMobile,
            articleCardTitleTag,
            ariaLabel,
            featuredArticle,
            submenuTitle,
            limit,
            limitMobile,
            showBreadcrumbs,
            homeBanner,
            pageTheme,
          },
        ],
      },
      header,
      footer,
      siteSettings,
      commonSettings,
    },
    pageContext: { breadCrumbs, lang },
  } = props;
  const articles = useMemo(() => {
    if (lang.includes('nz')) {
      return { nodes: allArticles.nodes.filter((item) => item.link.includes(path)) };
    }
    return intialArticles;
  }, [lang, path, allArticles, intialArticles]);
  const [highlightedArticle] = useState<UmbracoTypes.IRelatedArticle | null>(() => {
    const newHighlightedArticle: UmbracoTypes.IRelatedArticle | null = featuredArticle?.[0]
      ? articles.nodes.filter((article) => article.link === featuredArticle[0].url)[0]
      : null;
    return newHighlightedArticle;
  });
  const { isTablet, isMobile } = useScreenRecognition();
  const location = useLocation();

  const ALPSettings = getSettingsPropsByStructure(commonSettings, 'Article Listing Page');
  const ADPSettings = getSettingsPropsByStructure(commonSettings, 'ADP Related Articles');
  const currentPage = submenu?.filter((item) => item?.link?.[0]?.url === location.pathname);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      className="article-listing-page-container"
      header={header}
      footer={footer}
    >
      <BreadCrumbs data={breadCrumbs} showBreadcrumbs={showBreadcrumbs} />
      {topBanner?.[0]?.properties ? <TopBanner {...topBanner[0].properties} /> : null}
      {homeBanner?.[0]?.properties ? <HomeBanner topBanner={homeBanner} /> : null}
      <div
        className={classnames('article-listing-page', {
          [`article-listing-page--${pageTheme}`]: pageTheme,
        })}
      >
        {useDropdownNavOnMobile && (isTablet || isMobile) ? (
          <SubNavigationALPMobile
            submenuTitle={submenuTitle}
            links={submenu}
            settings={{ title: currentPage?.[0]?.name }}
          />
        ) : (
          <SubNavigationALP categories={submenu} submenuTitle={submenuTitle} />
        )}

        {highlightedArticle && featuredArticle ? (
          <FeaturedArticle
            link={featuredArticle}
            ariaButton={ariaLabel}
            featuredArticles={{ nodes: [highlightedArticle] }}
            imageAlign={['left']}
          />
        ) : null}
        <Container fluid>
          <ArticleCardsList
            limit={limit}
            limitMobile={limitMobile}
            btnText={ALPSettings?.btnText}
            ariaMore={ALPSettings?.ariaMore}
            articles={articles.nodes.filter((item) => item.id !== highlightedArticle?.id)}
            articleCardTitleTag={articleCardTitleTag}
            commonArticleCardTitleTag={ADPSettings?.commonArticleCardTitleTag}
          />
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ArticleListingQuery(
    $articlesLinks: [String]
    $link: String = ""
    $lang: String
    $articleSortInput: UmbracoArticlesSortInput
  ) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
          btnText
          ariaMore
          commonArticleCardTitleTag
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    page: allUmbracoArticleListing(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        id
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        openGraphImageUrl
        link
        ariaLabel
        limit
        limitMobile
        showBreadcrumbs
        submenuTitle
        featuredArticle {
          url
          name
        }
        topBanner {
          ...FragmentTopBanner
        }
        homeBanner {
          ...FragmentHomePageTopBanner
        }
        submenu {
          name
          articleCategory
          id
          link {
            url
            name
          }
          linkAria
        }
        useDropdownNavOnMobile
        articleCardTitleTag
        pageTheme
      }
    }
    articles: allUmbracoArticles(
      filter: { link: { in: $articlesLinks } }
      sort: $articleSortInput
    ) {
      nodes {
        id
        link
        title
        shortTitle
        shortText
        label
        text
        alt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentMiddleImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        tags {
          id
          name
          articleCategory
          link {
            name
          }
        }
      }
    }
    allArticles: allUmbracoArticles(sort: $articleSortInput) {
      nodes {
        id
        link
        title
        shortTitle
        shortText
        label
        text
        alt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentMiddleImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        tags {
          id
          name
          articleCategory
          link {
            name
          }
        }
      }
    }
  }
`;

export default ArticleListingPage;
